<template>
  <div>
    <a-modal
      v-model="detailShow"
      title="查看详情"
      width="900px"
      footer
      class="data-details-dialog"
      @cancel="cancel">
      <div style="max-height: 700px; overflow-y: auto;">
        <div class="title">资料详情</div>
        <div class="detail-content">
          <div class="detail-item">
            资料报价：
            <span>{{ details.price }}元</span>
          </div>
          <div class="detail-item">
            接收邮箱：
            <span>{{ details.mailbox }}</span>
          </div>
          <div class="detail-item">
            需求时间截止时间：
            <span>{{ details.endTime }}</span>
          </div>
          <div class="detail-item">
            专业：
            <span>{{ (positionArr.find((item) => item.id == details.position) || {}).majorName }}</span>
          </div>
          <div class="detail-item">
            基本技能：
            <span>{{ (skillArr.find((item) => item.id == details.skill) || {}).name }}</span>
          </div>
          <div class="detail-item">
            从业资格证：
            <span>{{ obj1[details.qualificationCertificate] }}</span>
          </div>
          <div class="detail-item">
            工作经历：
            <span>{{ obj2[details.experience] }}</span>
          </div>
          <div class="detail-item">
            职称等级：
            <span>{{ obj3[details.skillLevelCertificate] }}</span>
          </div>
          <div class="detail-item" style="width: 100%">
            需求内容：
            <span>{{ details.content }}</span>
          </div>
        </div>
        <template v-if="!!fileList.length">
          <a-table :columns="columns" :data-source="fileList" :pagination="false">
            <a-button slot="action" type="link" slot-scope="text, row" @click="handleClickDown(row)">下载</a-button>
          </a-table>
        </template>
        <template v-if="details.responseUser">
          <div class="title">合作人员</div>
          <div class="detail-content">
            <div class="detail-item">
              姓名：
              <span>{{ details.responseUser.realName }}</span>
            </div>
            <div class="detail-item">
              年龄：
              <span>{{ details.responseUser.age }}</span>
            </div>
            <div class="detail-item">
              联系电话：
              <span>{{ details.responseUser.phone }}</span>
            </div>
            <template v-if="responseUser.type === 1">
              <div class="detail-item">
                工种：
                <span>{{ responseUser.positionName }}</span>
              </div>
              <div class="detail-item">
                专业技能：
                <span>{{ responseUser.professionalSkill }}</span>
              </div>
              <div class="detail-item">
                基本技能：
                <span>{{ responseUser.baseSkill }}</span>
              </div>
              <div class="detail-item">
                项目经历：
                <span>{{ obj2[responseUser.experience] }}</span>
              </div>
              <div class="detail-item">
                技术职称证：
                <span>{{ obj3[responseUser.skillLevelCertificate] }}</span>
              </div>
            </template>
            <template v-else>
              <div class="detail-item">
                专业：
                <span>{{ responseUser.positionName }}</span>
              </div>
              <div class="detail-item">
                基本技能：
                <span>{{ responseUser.baseSkill }}</span>
              </div>
              <div class="detail-item">
                从业资格证：
                <span>{{ obj1[responseUser.qualificationCertificate] }}</span>
              </div>
              <div class="detail-item">
                工作经历：
                <span>{{ obj2[responseUser.experience] }}</span>
              </div>
              <div class="detail-item">
                职称等级：
                <span>{{ obj3[responseUser.skillLevelCertificate] }}</span>
              </div>
            </template>
          </div>
        </template>
        <template v-if="!!userFiles.length && details.responseUser">
          <a-table :columns="columns" :data-source="userFiles" :pagination="false">
            <a-button slot="action" type="link" slot-scope="text, row" @click="handleClickDown(row)">下载</a-button>
          </a-table>
        </template>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { employmentEmploybaseSkill, employmentEmployDictPosition } from '@/api/workDemand'
import { employmentLearnDetail } from '@/api/msg'

const obj1 = {
  1: '安全员',
  2: '建造师',
  0: '不限'
}

const obj2 = {
  1: '30万机组以下',
  2: '30-60万机组',
  3: '100万及以上机组',
  0: '不限'
}

const obj3 = {
  1: '初级',
  2: '中级',
  3: '高级',
  0: '不限',
  '-1': '特殊工种证书'
}

export default {
  name: 'Index',
  data () {
    return {
      detailShow: false,
      details: {},
      columns: [
        {
          title: '文件名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      positionArr: [],
      skillArr: [],
      obj1,
      obj2,
      obj3,
      responseUser: {}
    }
  },
  computed: {
    fileList () {
      const { materialDemandFiles = [] } = this.details
      return materialDemandFiles
    },
    userFiles () {
      const path = this.details.path || ''
      return path.split(',').map(item => {
        const itemArr = item.split('/')
        return {
          path: item,
          name: itemArr[itemArr.length - 1]
        }
      })
    }
  },
  created () {
    employmentEmployDictPosition({ type: 2 }).then((res) => {
      this.positionArr = res.data
    })
    employmentEmploybaseSkill({ type: 2 }).then((res) => {
      this.skillArr = res.data.baseSkillDtoList
    })
  },
  methods: {
    detail (val) {
      this.details = val
      this.detailShow = true
      if (val.responseUser) {
        employmentLearnDetail({ userId: val.responseUser.userId }).then((res) => {
          this.responseUser = res.data
        })
      }
    },
    handleClickDown ({ path, name: title }) {
      const arr = path.split('.')
      const fileType = arr[arr.length - 1]
      this.downFile({
        fileName: path,
        title,
        fileType
      })
    },
    downFile ({ fileName, title, fileType }) {
      this.$spin.show()
      try {
        var blobType
        switch (fileType) {
          case 'doc':
            blobType = 'application/msword'
            break
          case 'xls':
            blobType = 'application/vnd.ms-excel'
            break
          case 'pdf':
            blobType = 'application/pdf'
            break
          case 'docx':
            blobType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            break
          case 'xlsx':
            blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            break
          default:
            break
        }
        this.axios({
          method: 'get',
          url: fileName,
          responseType: 'blob'
        })
          .then((res) => {
            const blob = new Blob([res], {
              type: blobType
            })
            const a = document.createElement('a')
            const URL = window.URL || window.webkitURL
            const href = URL.createObjectURL(blob)
            a.href = href
            a.download = title
            console.log(title)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(href)
          })
          .finally(() => {
            this.$spin.hide()
          })
      } catch (e) {
        this.$spin.hide()
        window.open(fileName, title)
      }
    },
    cancel () {
      this.details = {}
      this.responseUser = {}
      this.detailShow = false
    }
  },
  destroyed () {}
}
</script>
<style lang="less" scoped>
.data-details-dialog {
  .title {
    font-size: 16px;
    font-weight: 550;
    color: #333333;
    margin: 10px 0;
  }
  .detail-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .detail-item {
    width: 33%;
    font-size: 16px;
    margin-bottom: 20px;
    color: #222222;
    span {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
.div_size {
  font-size: 18px;
}
</style>
